<template>
  <!-- @click="(val) => $emit('update:is-table-visible', false)" -->
  <v-btn-toggle
    v-model="isTableVisibleLocal"
    @change="(val) => $emit('update:is-table-visible', val)"
  >
    <v-btn
      icon
      small
      :value="true"
    >
      <v-icon>
        {{ icons.mdiTable }}
      </v-icon>
    </v-btn>
    <v-btn
      icon
      small
      outlined
      :value="false"
    >
      <v-icon>
        {{ icons.mdiMenu }}
      </v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import {
  mdiTable,
  mdiMenu,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    isTableVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isTableVisibleLocal = ref(props.isTableVisible)

    return {
      isTableVisibleLocal,
      icons: {
        mdiTable,
        mdiMenu,
      },
    }
  },
}
</script>
