<template>
  <v-select
    v-model="sortByLocal"
    :items="tableColumnsOptionsFiltered"
    item-text="text"
    item-value="value"
    placeholder="Sort By..."
    outlined
    dense
    hide-details
    chips
    style="max-width: min-content; min-width: 140px;"
    @change="(value) => onChangeSort(value)"
  >
    <template #selection="data">
      <v-chip
        small
        v-bind="data.attrs"
        :input-value="data.selected"
        @click.stop="onTogleOrder"
      >
        {{ data.item.text }}
        <v-icon
          right
          size="18"
        >
          {{ sortDescLocal ? icons.mdiSortAscending : icons.mdiSortDescending }}
        </v-icon>
      </v-chip>
    </template>
    <template #item="data">
      <template>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.text }}
            <v-icon
              v-if="data.item.value === sortByLocal"
              right
            >
              {{ sortDescLocal ? icons.mdiSortAscending : icons.mdiSortDescending }}
            </v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
import { mdiSortAscending, mdiSortDescending } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

export default {
  // TODO - sortBy y sortDesc como Array (Es posible que se arregle el watch deep)
  props: {
    sortBy: {
      type: String,
      required: false,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    tableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const tableColumnsOptionsFiltered = computed(() => props.tableColumns.filter(item => item.sortable === true))

    // Local data
    const sortByLocal = ref(props.sortBy)
    const sortDescLocal = ref(props.sortDesc)

    // 2 data binding
    // watch(() => props.sortBy, () => {
    //   sortByLocal.value = props.sortBy
    // })
    // watch(() => props.sortDesc, () => {
    //   sortDescLocal.value = props.sortDesc
    // })
    watch(
      [
        () => props.sortBy,
        () => props.sortDesc,
      ], () => {
        sortByLocal.value = props.sortBy
        sortDescLocal.value = props.sortDesc
      },
    )

    // External Sort and Order
    //  Sort: Al seleccionar un campo -> Indicamos ordenación por defecto
    //  Order: TogleOrder
    const onChangeSort = value => {
      sortByLocal.value = value
      sortDescLocal.value = false // Set default order: ASC

      emit('update:sortBy', sortByLocal.value)
      emit('update:sortDesc', sortDescLocal.value)
    }

    // Togle secuence: false -> true -> null
    const onTogleOrder = () => {
      // No togle is posible
      if (sortDescLocal.value === null) return

      // Checking... Current sort is TRUE -> Next is take off
      if (sortDescLocal.value === true) {
        // Take off sort and order
        sortByLocal.value = null
        sortDescLocal.value = null
      } else {
        // Togle order
        sortDescLocal.value = !sortDescLocal.value
      }

      emit('update:sortBy', sortByLocal.value)
      emit('update:sortDesc', sortDescLocal.value)
    }

    return {
      tableColumnsOptionsFiltered,

      // Data
      sortByLocal,
      sortDescLocal,

      // External Sort and Order
      onChangeSort,
      onTogleOrder,

      icons: {
        mdiSortAscending,
        mdiSortDescending,
      },
    }
  },
}
</script>
