<template>
  <div class="d-flex align-center flex-nowrap mt-4 px-2 text-xs">
    <!-- Pagination: Usamos options porque está referenciada con v-data-table -->
    <span class="pl-2">Items per page</span>
    <v-select
      v-model="optionsLocal.itemsPerPage"
      :items="itemsPerPageOptions"
      dense
      flat
      solo
      hide-details
      style="max-width: min-content;"
      @change="onChangeItemsPerPage"
    ></v-select>
    <v-spacer></v-spacer>
    <div>{{ pagination.pageStart }} - {{ pagination.pageStop }} of {{ pagination.itemsLength }}</div>
    <v-pagination
      v-model="optionsLocal.page"
      :length="pagination.pageCount"
      :total-visible="7"
    ></v-pagination>
  </div>
</template>

<script>
import { mdiSortAscending, mdiSortDescending } from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'

export default {
  // TODO - sortBy y sortDesc como Array (Es posible que se arregle el watch deep)
  props: {
    options: {
      type: Object,
      required: true,
    },
    itemsLength: {
      type: Number,
      require: false,
      default: 0,
    },
    itemsPerPageOptions: {
      type: Array,
      require: false,
      default: () => [1, 2, 5, 10, 15, -1],
    },
  },
  setup(props, { emit }) {
    // Options es 2 data binding (Escuchamos los cambios)
    const optionsLocal = ref(props.options)
    watch(
      () => props.options,
      () => {
        optionsLocal.value = props.options
      },
    )

    // * AHORA NO EXISTE ESTO, SE CAMBIA SIEMPRE A LA PAGINA 1 * //
    //
    // Check and return new page when overPage
    // Situación de pagina por encima del total de páginas
    //  Cuando indicamos ALL => Se reduce a 1 página
    //  Cuando visualizamos más items por páginas => Se reducen las páginas
    // const checkOverPage = (page, itemsPerPage, itemsLength) => {
    //   const pageCount = itemsPerPage === -1 ? 1 : Math.ceil(itemsLength / itemsPerPage)
    //   if (pageCount > 0 && page > pageCount) {
    //     return pageCount
    //   }

    //   return 0
    // }

    // Hemos cambiado los items por página => Debemos comprobar si tambien cambiamos la página
    const onChangeItemsPerPage = () => {
      // const newPage = checkOverPage(optionsLocal.value.page, optionsLocal.value.itemsPerPage, props.itemsLength)
      // if (newPage > 0) {
      //   optionsLocal.value.page = newPage
      // }
      // Force to page 1
      optionsLocal.value.page = 1

      emit('update:options', optionsLocal.value)
    }
    const onChangePage = () => {
      emit('update:options', optionsLocal.value)
    }

    // Pagination Object https://vuetifyjs.com/en/api/v-data-table/#events-pagination
    //  page: number,
    //  itemsPerPage: number,
    //  pageStart: number,
    //  pageStop: number,
    //  pageCount: number,
    //  itemsLength: number
    // Watchers: optionsLocal & props.itemsLength
    const pagination = computed(() => {
      const { page, itemsPerPage } = optionsLocal.value
      const { itemsLength } = props

      const pageCount = itemsPerPage === -1 ? 1 : Math.ceil(itemsLength / itemsPerPage)
      const pageStart = itemsPerPage === -1 ? 1 : ((page - 1) * itemsPerPage) + 1
      const pageStop = itemsPerPage === -1 || pageCount === page ? itemsLength : page * itemsPerPage

      // Evitamos pasar page y itemsPerPage (Estes campos se sincronizan con options)
      return {
      // page,
      // itemsPerPage,
        pageStart,
        pageStop,
        pageCount,
        itemsLength,
      }
    })

    return {
      // Data
      optionsLocal,
      pagination,

      // External Sort and Order
      onChangeItemsPerPage,
      onChangePage,

      icons: {
        mdiSortAscending,
        mdiSortDescending,
      },
    }
  },
}
</script>
