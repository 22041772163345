<template>
  <!-- Menu View Filter Left Side -->
  <!-- :icon="icons.mdiMenu" -->
  <v-badge
    :value="countFiltering && !isLeftSidebarOpen"
    :content="countFiltering"
    color="error"
    bordered
    overlap
    class="mr-2"
  >
    <v-btn
      v-show="!isLeftSidebarOpen"
      :icon="false"
      outlined
      small
      color="primary"
      @click="$emit('update:isLeftSidebarOpen', true)"
    >
      <v-icon size="22">
        {{ icons.mdiTune }}
      </v-icon>
      <span class="pl-2">Filtrar</span>
    </v-btn>
  </v-badge>
</template>

<script>
import {
  mdiMenu,
  mdiTune,
} from '@mdi/js'

export default {
  props: {
    countFiltering: {
      type: Number,
      default: null,
    },

    isLeftSidebarOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      icons: {
        mdiMenu,
        mdiTune,
      },
    }
  },
}
</script>
